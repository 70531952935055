import { createRouter, createWebHistory } from 'vue-router'
import { authGuard } from "@auth0/auth0-vue"
import { setupRouterGuard } from './router-guard'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/HomeView.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/t',
    name: 'therapist-root',
    component: () => import('@/pages/TherapistApp/TherapistView.vue'),
    meta: { requiresTherapist: true },
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'therapist-home',
        component: () => import('@/pages/HomeView.vue'),
      },
      {
        path: 'chat/:chatId',
        name: 'therapist-chat-id',
        component: () => import('@/pages/HomeView.vue'),
      },
      {
        path: 'metrics',
        name: 'therapist-metrics',
        component: () => import('@/pages/TherapistApp/DashboardApp/DashboardApp.vue'),
      },
      {
        path: 'user',
        name: 'therapist-account',
        component: () => import('@/pages/TherapistApp/AccountApp/AccountApp.vue'),
      },
      {
        path: 'activities',
        name: 'therapist-activities',
        component: () => import('@/pages/TherapistApp/ActivitiesApp/ActivitiesApp.vue'),
      },
      {
        path: 'sessions',
        name: 'therapist-sessions',
        component: () => import('@/pages/TherapistApp/SessionsApp/SessionsApp.vue'),
      }
    ],
  },
  {
    path: '/c',
    name: 'client-root',
    component: () => import('@/pages/ClientApp/ClientApp.vue'),
    meta: { requiresClient: true },
    beforeEnter: authGuard,
    children: [
      {
        path: 'home',
        name: 'client-home',
        // component: () => import('@/pages/ClientApp/ClientApp.vue'),
        component: () => import('@/pages/ClientApp/ActivitiesApp/ClientActivitiesView.vue'),
      },
      {
        path: 'account-settings',
        name: 'client-account',
        component: () => import('@/pages/ClientApp/ClientSettings/ClientSettings.vue'),
      },
      {
        path: 'activities',
        component: () => import('@/pages/ClientApp/ActivitiesApp/ActivitiesLayout.vue'),
        children: [
          {
            path: '',
            name: 'client-activities',
            component: () => import('@/pages/ClientApp/ActivitiesApp/ClientActivitiesView.vue'),
          },
        ]
      },
      {
        path: 'your-therapist',
        name: 'client-therapist',
        component: () => import('@/pages/ClientApp/ClientTherapistView/ClientTherapistView.vue'),
      },
      {
        path: 'your-sessions',
        name: 'client-sessions',
        component: () => import('@/pages/ClientApp/ClientSessionsView/ClientSessionsView.vue'),
      },
      {
        path: 'sessions/:sessionId',
        name: 'client-session-detail',
        component: () => import('@/pages/ClientApp/ClientSessionView/ClientSessionView.vue'),
      }

    ],
  },
  {
    path: '/c/activities/:activityId',
    name: 'client-activity-detail',
    component: () => import('@/pages/ClientApp/ActivitiesApp/ClientActivityDetail.vue'),
    meta: { public: true },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/pages/ViewPermission403.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/pages/ViewPermission404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

setupRouterGuard(router)

export default router
