<template>
  <div id="app">
    <OffCanvas
      v-if="offcanvasVisibility.first"
      id="first"
      title="First Offcanvas"
      :isVisible="offcanvasVisibility.first"
      @update:isVisible="(val) => (offcanvasVisibility.first = val)"
    >
      <p>Content for the first offcanvas.</p>
    </OffCanvas>

    <OffCanvas
      v-if="offcanvasVisibility.second"
      id="second"
      title="Second Offcanvas"
      :isVisible="offcanvasVisibility.second"
      @update:isVisible="(val) => (offcanvasVisibility.second = val)"
    >
      <p>Content for the second offcanvas.</p>
    </OffCanvas>

    <BlueprintVersionsOffCanvas
      v-if="offcanvasVisibility.blueprintVersions"
      :id="'blueprintVersions'"
      :isVisible="offcanvasVisibility.blueprintVersions"
      @update:isVisible="(val: any) => (offcanvasVisibility.blueprintVersions = val)"
      @openVersion="openVersion"
    />

    <CreateActivityOffCanvas
      v-if="offcanvasVisibility.createBlueprint"
      :id="'createBlueprint'"
      :isVisible="offcanvasVisibility.createBlueprint"
      @update:isVisible="(val) => (offcanvasVisibility.createBlueprint = val)"
    />

    <ViewBlueprintOffCanvas
      v-if="offcanvasVisibility.viewBlueprint"
      :id="'viewBlueprint'"
      :isVisible="offcanvasVisibility.viewBlueprint"
    />

    
  </div>
</template>

<script setup lang="ts">
import { useOffcanvasStore } from "@/stores/offcanvas";
import OffCanvas from "@/components/atoms/OffCanvas.vue";
import { computed } from "vue";
import BlueprintVersionsOffCanvas from "@/pages/TherapistApp/ActivitiesApp/ActivititesOffCanvas/BlueprintVersions/BlueprintVersionsOffCanvas.vue";
import CreateActivityOffCanvas from "@/pages/TherapistApp/ActivitiesApp/ActivititesOffCanvas/CreateActivity/CreateActivityOffCanvas.vue";
import ViewBlueprintOffCanvas from "@/pages/TherapistApp/ActivitiesApp/ActivititesOffCanvas/ViewBlueprint/ViewBlueprintOffCanvas.vue";
import  { useActivityVersionsStore } from "@/stores/activities/activity_versions";

// Access store state and actions
const offcanvasStore = useOffcanvasStore();
const activityVersionsStore = useActivityVersionsStore();
const offcanvasVisibility = computed(() => offcanvasStore.offcanvasVisibility);

const openVersion = async (id: string) => {
  await offcanvasStore.toggleOffcanvas('viewBlueprint');
  await activityVersionsStore.fetchVersion(id);
}
</script>