// axios.ts

import axios from 'axios';
import { ref } from "vue";
import { getAuthToken } from '@/utils/auth0'

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Client-Location': Intl.DateTimeFormat().resolvedOptions().timeZone,
};
const apiUrl = import.meta.env.VITE_API_ROOT;

export const axiosRoot = axios.create({
  baseURL: apiUrl,
  headers: defaultHeaders,
});

export async function useAxios() {
  const token = ref('');
  const accessToken = await getAuthToken();
  token.value = accessToken;
  
  if (token.value) {
    axiosRoot.defaults.headers.Authorization = `Bearer ${token.value}`;
  }
  
  return { axiosRoot };
}