import { defineStore } from 'pinia'
import type { SubscriptionStatus } from '@/pages/ChatApp/interface';
import { remainingHours } from '@/utils/time';

interface chatDetails {
  id: string,
  clientId: string,
  userName: string,
  userImage: string,
  userInfo: string,
  phoneNumber: string,
  email: string
  subscriptionStatus?: SubscriptionStatus,
  lastUserMessage: string,
}


interface ChatSelectedState {
  chatDetails: chatDetails,
  chatIdReassign: string | null,
  loadingChatDetails: boolean,
  isChatDetailsFolded: boolean,
  activeSideBar: 'general' | 'form' | 'template' | 'template-details' | 'subscription' | 'new-summary' | 'summary' | 'tags',
}

export const useSelectedChatDetailsStore = defineStore('selected_chat_details', {
  state: (): ChatSelectedState => ({isChatDetailsFolded: window.innerWidth<768, activeSideBar: 'general'} as ChatSelectedState),
  getters: {
    isChatExpired: (state) => state.chatDetails? remainingHours(state.chatDetails.lastUserMessage) === 0: false,
    getChatIdReassign: (state) => state.chatIdReassign,
  },
  actions: {
    toggleFoldChatDetails() {
      this.isChatDetailsFolded = !this.isChatDetailsFolded
    },
    setSelectedChatDetails(chatDetails: chatDetails) {
      this.chatDetails = chatDetails
    },
    reassignClient() {
      // TODO: Implement reassignClient
    },
    setActiveSideBar(sideBar: ChatSelectedState['activeSideBar']) {
      this.activeSideBar = sideBar
    },
    setChatIdReassign(chatId: string | null) {
      this.chatIdReassign = chatId
    }
  }
})
