<template>
    <OffCanvas
      :id="props.id"
      :title="i18n('versions')"
      :isVisible="props.isVisible"
    >
      <template #header>
        <!-- <BaseButton 
          @click="handleButtonClick"
          :text="i18n('newVersion')"
          color="primary"
          :icon="'bi-plus-lg'"
        /> -->
      </template>

      <div class="loading" v-if="activityVersionsStore.loadingVersions">
        <LoadingSpinner />

      </div>
      <div class="loaded" v-else>

        <div class="versions-layout">
          <BlueprintVersionOffCanvasCard
            v-for="version in activityVersionsStore.versions"
            :key="version.id"
            :id="version.id.toString()"
            :version="version"
            :title="version.title"
            :createdAt="version.createdAt.toString()"
            :language="version.languageCode"
            :createdBy="version.createdBy"
            @open-version="emit('openVersion', version.id)"
          />
        </div>
      </div>

    </OffCanvas>
</template>

<script setup lang="ts">
import BaseButton from "@/components/atoms/buttons/BaseButton.vue";
import OffCanvas from "@/components/atoms/OffCanvas.vue";
import { useI18n } from "@/utils/i18n";
import { useActivityVersionsStore } from "@/stores/activities/activity_versions";
import BlueprintVersionOffCanvasCard from "./BlueprintVersionOffCanvasCard.vue";
import LoadingSpinner from "@/components/atoms/LoadingSpinner.vue";
import type { OffcanvasVisibility } from "@/stores/offcanvas";

const activityVersionsStore = useActivityVersionsStore();

const emit = defineEmits(['openVersion']);
const props = defineProps<{
  id: OffcanvasVisibility;
  isVisible: boolean;
}>();


const i18nLocales = {
  es_ES: {
    versions: 'Versiones',
    newVersion: 'Nueva versión',
  },
  en_US: {
    versions: 'Versions',
    newVersion: 'New version',
  },
  ca_ES: {
    versions: 'Versions',
    newVersion: 'Nova versió',
  },
}
const { i18n } = useI18n(i18nLocales);


</script>