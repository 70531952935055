// auth-service.js
import { ref } from 'vue';
import { createAuth0 } from '@auth0/auth0-vue';

// Constants
const RETRY_ATTEMPTS = 2;
const RETRY_DELAY = 500;
const TOKEN_EXPIRY_BUFFER = 36000000; // 36000 seconds (10 hours) in milliseconds



// Create Auth0 instance
export const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  authorizationParams: {
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
    scope: 'openid profile email offline_access',
  },
  httpTimeoutInSeconds: 60
});

// State management
const tokenCache = ref(null);
let tokenPromise = null;

export class AuthError extends Error {
  constructor(message, originalError) {
    super(message);
    this.name = 'AuthError';
    this.originalError = originalError;
  }
}

// Utility functions
const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const isTokenExpired = (cache) => {
  if (!cache) return true;
  return Date.now() >= cache.expiresAt - TOKEN_EXPIRY_BUFFER;
};

const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  } catch (error) {
    throw new AuthError('Failed to parse JWT token', error);
  }
};


// Main token retrieval function
export const getAuthToken = async () => {
  
  // Return cached token if it's still valid
  if (tokenCache.value && !isTokenExpired(tokenCache.value)) {
    
    return tokenCache.value.token;
  }

  // If there's an ongoing token request, wait for it
  if (tokenPromise) {
    
    try {
      return await tokenPromise;
    } catch (error) {
      console.error('[Auth] Existing token request failed:', error);
      tokenPromise = null;
    }
  }

  // Create new token request
  tokenPromise = (async () => {
    let attempts = 0;
    
    while (attempts < RETRY_ATTEMPTS) {
      try {
        let token;
        

        if (!auth0.isAuthenticated.value) {
          
          await wait(RETRY_DELAY);
          attempts++;
          continue;
        }
        token = await auth0.getAccessTokenSilently();
      

        const decoded = parseJwt(token);
        
        tokenCache.value = {
          token,
          expiresAt: decoded.exp * 1000,
        };

        return token;
      } catch (error) {
        console.error('[Auth] Error getting token:', error);
        attempts++;
        if (attempts === RETRY_ATTEMPTS) {
          throw new AuthError(
            'Failed to get authentication token after multiple attempts',
            error
          );
        }
        
        await wait(RETRY_DELAY * attempts);
      }
    }

    throw new AuthError('Authentication failed: Max retry attempts exceeded');
  })();

  try {
    const token = await tokenPromise;
    
    return token;
  } catch (error) {
    console.error('[Auth] Final token retrieval error:', error);
    throw error;
  } finally {
    tokenPromise = null;
  }
};